import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Title } from "../../components/Globals";
import { Link } from 'gatsby';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { BsFillPlusSquareFill,  } from "react-icons/bs";
import { AiFillMinusSquare } from "react-icons/ai";

const SalesforceTrainingPage = () => {

    const [course1, setCourse1] = React.useState(false);
    const [course2, setCourse2] = React.useState(false);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
        let formData = new FormData();
        formData.append('from', event.target.email.value);
        formData.append('to','prakash@cymetrixsoft.com');
        formData.append('subject', event.target.name.value+' - from Training Page');
        formData.append('text', 'Name :'+event.target.name.value);
        formData.append('text', 'Course :'+event.target.course.value);
        formData.append('text', 'Contact :'+event.target.contact.value);
        formData.append('text', 'Country :'+event.target.country.value);
        formData.append('text','Location :'+event.target.location.value);
        formData.append('text','Message :'+event.target.message.value);
       
        fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
          method: 'post',
          headers: headers,
          body: formData,
        })
        .then(function (data) {
          if(data.status === 200) {
            //alert('Message sent successfuly');
            window.location = 'https://www.cymetrixsoft.com/Thankyou/';
          }
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      }
    return (
        <Layout>
     <div className="salesforcetraining-background  flex flex-col">
     <p className="block text-base xl:text-5xl mt-16 xl:px-48 xl:mt-20 md:mt-16 sm:mt-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
        text-white text-uppercase text-center">
         Salesforce Certification Training
        </p>
        <p className="block text-base xl:text-xl mt-4 md:mt-6 lg:text-lg md:text-base sm:text-base sma:text-base
        text-white text-uppercase text-center">
Get the essential skills and knowledge in Salesforce through our training from our Salesforce Experts         </p>
          <a href="../services/salesforce-certification-training" className="uppercase font-bold px-8 lg:px-10 py-3 mt-10 rounded bg-gray-500 text-gray-100 hover:bg-hover focus:shadow-outline focus:outline-none transition duration-300"> Salesforce Certification </a>
</div>

            <div className="max-w-6xl mx-auto justify-center">
            <Helmet>
        <title>Salesforce Training | Cymetrix Software</title>
        <meta name="description" content="Cymetrix salesforce.com CRM partners India provides a job oriented salesforce training from basics to the master level of salesforce admin & development"/>
      <meta name="keywords" content="Salesforce training, Cymetrix, cymetrixsoft, Cymetrixsoftware "/>
         <link rel="canonical" href="https://www.cymetrixsoft.com/services/salesforce-training/" />
      </Helmet>
      
               <h1 className="sma:text-xl text-3xl text-center text-extrabold pt-16" style={{color: '#1e407b'}}>SALESFORCE TECHNICAL PROGRAM</h1>
                <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap max-w-screen-xl sma:py-1 py-10 md:py-14">
                <div className="sma:px-3 md:w-1/2 lg:w-2/3 px-6 flex md:mb-10">
                        <div className="sma:mt-3 flex flex-col max-w-2xl items-center mt-12">
                            <div className="px-3 py-4 leading-relaxed">
                                <p class="text-justify">Salesforce-based company in USA - Cymetrix Software provides Salesforce Technical Training. This Technical Training program offers a comprehensive education on the Salesforce Platform and salesforce implementation services. This Training Program also enables professionals to take the certification exams. Offshore Salesforce Development Company Cymetrix Software make sure that you get to know every aspect of Salesforce Platform with complete knowledge of salesforce CRM software implementations. We have a syllabus that covers a wide range of topics and gives the participant a firm grip on whatever they need to use the Salesforce Platform. So come join the best Salesforce Technical Training Program provided by a salesforce certified consulting partner and see that your career prosper as you have never imagined before.</p>
                            </div>

                            <div className="bg-gray-300 w-full px-5 py-8">
                                <h1 className="text-2xl font-extrabold text-left underline">COURSE 1</h1>
                                <p className="text-lg font-bold mt-1">Administrative Essentials for New Admins in Lightning Experience (ADX201)</p>
                                <h1 className="text-lg font-bold mt-5 ml-3">Overview</h1>
                                <p className="text-base font-medium ml-3 text-justify">Extensive and interactive, Administrative Essentials for New Admins in Lightning Experience is the core training that ensures your success with Salesforce Lightning. It’s a must for new administrators, and we recommend completing this course before starting a Salesforce deployment or when taking over an existing deployment.</p>
                                <h1 className="text-lg font-bold mt-5 ml-3">Who should take this course?</h1>
                                <p className="text-base font-medium ml-3 text-justify">New system administrators responsible for the setup, configuration, and maintenance of their organization’s Salesforce applications. Other groups that would benefit from deepening their knowledge of Salesforce Lightning Experience, including power users, sales operations, and IT managers.</p>
                                <h1 className="text-lg font-bold mt-5 ml-3">When you complete this course, you will be able to:</h1>
                                <ul className="list-disc text-base list-inside leading-8 px-3">
                                    <li>Customize your application, including page layouts, fields, tabs, and business processes in Lightning Experience.</li>
                                    <li>Learn how security settings created in Salesforce Classic are applied in Lightning.</li>
                                    <li>Maintain and import clean data in Lightning.</li>
                                    <li>Use Lightning features to create high-value reports and dashboards</li>
                                    <li>Understand how workflow automation complies with Lightning</li>
                                </ul>
                                <div className="flex">
                                    {
                                        !course1 ? <BsFillPlusSquareFill className="h-6 w-6 mt-5" onClick={() => setCourse1(true)}/> : <AiFillMinusSquare className="h-8 w-8 mt-4" onClick={() => setCourse1(false)}/>
                                    }
                                    <h1 className="text-lg font-bold mt-5 ml-3">Lessons and Topics</h1>
                                </div>
                                {
                                    course1 ? <div className="">
                                        <h1 className="ml-1 my-2 font-bold">Getting Around the App</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Data Model and Navigation</li>
                                            <li>Lightning Experience</li>
                                            <li>Help & Training</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Getting Your Organization Ready for Users</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Setting Up the Company Profile</li>
                                            <li>Configuring the User Interface</li>
                                            <li>Setting Up Activities and Calendars</li>
                                            <li>Configuring Search Settings</li>
                                            <li>Setting Up Chatter Groups</li>
                                            <li>Mobile Access with Salesforce</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Setting Up and Managing Users</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                        <li>Managing User Profiles</li>
                                        <li>Managing Users</li>
                                        <li>Setting Up Chatter Free Users and Invites</li>
                                        <li>Troubleshooting Login Issues</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Security and Data Access</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                        <li>Restricting Logins</li>
                                        <li>Determining Object Access</li>
                                        <li>Setting Up Record Access</li>
                                        <li>Creating a Role Hierarchy</li>
                                        <li>Dealing with Record Access Exceptions</li>
                                        <li>Managing Field-level Security</li>
                                        </ul>

                                        <h1 className="ml-1 my-2 font-bold">Object Customizations</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                        <li>Administering Standard Fields</li>
                                        <li>Creating New Custom Fields</li>
                                        <li>Creating Selection Fields: Picklists and Lookups</li>
                                        <li>Creating Formula Fields</li>
                                        <li>Working with Page Layouts</li>
                                        <li>Working with Record Types and Business Processes</li>
                                        <li>Maintaining Data Quality</li>
                                        </ul>


                                        <h1 className="ml-1 my-2 font-bold">Managing Data</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                        <li>Import Wizards</li>
                                        <li>Data Loader</li>
                                        <li>Data.com</li>
                                        <li>Mass Transfer</li>
                                        <li>Backing Up Data</li>
                                        <li>Mass Delete and Recycle Bin</li>
                                        </ul>

                                        <h1 className="ml-1 my-2 font-bold">Automation</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                        <li>Email Templates</li>
                                        <li>Workflow Rules</li>
                                        <li>Process Builder</li>
                                        <li>Lead Automation</li>
                                        </ul>

                                        <h1 className="ml-1 my-2 font-bold">Managing the Support Process</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                        <li>Managing and Resolving Cases</li>
                                        <li>Customizing a Support Process</li>
                                        <li>Automating Support</li>
                                        <li>Understanding the Salesforce Console for Service</li>
                                        <li>Collaborating in the Service Cloud</li>
                                        <li>Analyzing Support Data</li>
                                        </ul>

                                        
                                       
                                       
                                        
                                    </div> : null
                                }
                            </div>
                            <hr className="w-full" />
                            <div className="bg-gray-300 w-full px-5 py-8 mt-5">
                                <h1 className="text-2xl font-extrabold text-left underline">COURSE 2</h1>
                                <p className="text-lg font-bold mt-1">Programmatic Development using Apex and Visualforce in Lightning Experience (DEX450)</p>
                                <h1 className="text-lg font-bold mt-5 ml-3">Overview</h1>
                                <p className="text-base font-medium ml-3 text-justify">Are you a programmer who wants to understand how to customize applications programmatically on the Salesforce platform? In this course, you will learn the core of the Apex programming language and Visualforce markup in order to customize your Salesforce applications. You will get hands-on experience building data objects (sObjects) and programmatically retrieving, manipulating, and storing the data associated with those objects. You will write custom logic using Apex triggers and classes, and test that logic using the built-in testing framework. You will explore how Apex code interacts with declarative customizations on the platform, and the nuances of working on a multi-tenant platform. You’ll then examine common techniques for designing solutions in Apex. These activities will culminate in an exercise building a complex trigger that takes advantage of the declarative aspects of the platform. You will get hands-on experience writing Visualforce pages to customize your user interface</p>
                                <h1 className="text-lg font-bold mt-5 ml-3">Who should take this course?</h1>
                                <p className="text-base font-medium ml-3 text-justify">This course is designed for programmatic developers who are new to the Salesforce platform, who need to be able to write programmatic customizations to both the business logic and user interface layers using Apex and Visualforce.</p>
                                <h1 className="text-lg font-bold mt-5 ml-3">When you complete this course, you will be able to:</h1>
                                <ul className="list-disc text-base list-inside leading-8">
                                    <li>Create and modify objects using the declarative interface</li>
                                    <li>Write business logic customizations using Apex triggers and classes. Those customizations will use SOQL and DML.</li>
                                    <li>Design programmatic solutions that take advantage of declarative customizations</li>
                                    <li>Describe how your trigger code works within the basics of the Save Order of Execution</li>
                                    <li>Describe some of the fundamental aspects of designing programs on a multi-tenant platform</li>
                                    <li>
                                    Write Visualforce markup and code to customize the user interface
                                    </li>
                                    <li>Use the built-in testing framework to test Apex and Visualforces
</li>
                                </ul>
                                <div className="flex">
                                {
                                        !course2 ? <BsFillPlusSquareFill className="h-6 w-6 mt-5" onClick={() => setCourse2(true)}/> : <AiFillMinusSquare className="h-8 w-8 mt-4" onClick={() => setCourse2(false)}/>
                                    }
                                    <h1 className="text-lg font-bold mt-5 ml-3">Lessons and Topics</h1>
                                </div>  
                                {
                                    course2 ? <div className="">
                                        <h1 className="ml-1 my-2 font-bold">Objects and Fields</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Describe the capabilities of objects on the Salesforce platform</li>
                                            <li>Create a custom object</li>
                                            <li>Create custom fields</li>
                                            <li>Create relationship fields</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Work Effectively with Custom Objects and Fields</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Create formula fields</li>
                                            <li>Create roll-up summary fields</li>
                                            <li>Describe the capabilities of record types</li>
                                        </ul>

                                        <h1 className="ml-1 my-2 font-bold">Programming with Apex</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Describe key aspects of Apex that differentiate it from other languages, such as Java and C#</li>
                                            <li>Describe why Apex transactions and governor limits must be considered when writing Apex</li>
                                            <li>Execute simple Apex</li>
                                            <li>Use the sObject data type, the primitive data types, and basic control statements in Apex</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Use SOQL to Query Your Org’s Data</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Write a basic query using Salesforce’s query language, SOQL</li>
                                            <li>Process the result of a query in Apex</li>
                                            <li>Create a query dynamically at run-time Use SOQL to Query Parent-Child Relationships</li>
                                            <li>Describe a relationship query</li>
                                            <li>Write a query that traverses a child-to-parent relationship</li>
                                            <li>Write a query that traverses a parent-to-child relationship
</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">DML Essentials</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>List the differences between the ways you can invoke DML operations</li>
                                            <li>Write Apex to invoke DML operations and handle DML errors</li>
                                            
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Trigger Essentials</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Describe what a trigger is used for</li>
                                            <li>Describe the syntax of a trigger definition</li>
                                            <li>Use trigger context variables
</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Apex Class Essentials</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Describe how Apex classes are used</li>
                                            <li>Define an Apex class</li>
                                            <li>Determine what data an Apex class can access
</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">The Save Order of Execution and Apex Transactions</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Describe key points in the Order of Execution</li>
                                            <li>Describe how triggers fit into and can be impacted by the Order of Execution</li>
                                            <li>Describe the lifecycle of an Apex Transaction</li>
                                            <li>Describe the memory lifecycle for static variables
</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Testing Essentials</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Describe Apex’s testing framework</li>
                                            <li>Create test data</li>
                                            <li>Write and run an Apex test</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Testing Strategies</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Describe practices for writing code that is easy to maintain and extend</li>
                                            <li>Write triggers and classes that assume batches of data as input</li>
                                            <li>Write code that works efficiently with the database, both in querying and using DML</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Strategies for Designing Efficient Apex Solutions</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Determine your code coverage percentages</li>
                                            <li>Create tests using best practices</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Trigger Design Strategies</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>List declarative mechanisms you can use to implement complex business logic, for what types of problems they are best used, and their limitations</li>
                                            <li>Describe ways in which you can use declarative functionality to improve your programmatic solutions</li>
                                           
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Creating Visualforce Pages</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Create a Visualforce page</li>
                                            <li>Reference a standard controller</li>
                                            <li>Launch a Visualforce page using a custom button</li>
                                            <li>Display data from a record in a Visualforce page</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Exploring the View and Controller Layers of Visualforce</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Create a Visualforce page</li>
                                            <li>Display related data</li>
                                            <li>Invoke standard controller actions</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Working with Custom Controllers and Controller Extensions</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Create controller extensions</li>
                                            <li>Create a custom controller</li>
                                            <li>Work with properties</li>
                                            <li>Use PageReferences</li>
                                            <li>Invoke custom methods in Visualforce pages</li>
                                        </ul>
                                        
                                        <h1 className="ml-1 my-2 font-bold">Working with List Controllers and SOSL Queries</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Use a standard list controller in a Visualforce page</li>
                                            <li>Create a SOSL query</li>
                                            <li>Create a custom list controller</li>
                                        </ul>
                                        <h1 className="ml-1 my-2 font-bold">Visualforce Development Considerations</h1>
                                        <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4">
                                            <li>Determine whether a declarative solution exists for your requirements</li>
                                            <li>Describe common governor limit issues and security concerns</li>
                                            <li>Describe Visualforce strategies Testing Visualforce Controllers</li>
                                            <li>Describe how a Visualforce controller interacts with the view</li>
                                            <li>
                                            Write tests for controller constructors
                                            </li>
                                            <li>Write tests for action methods, getters, setters, and properties</li>
                                        </ul>
                                    </div> : null
                                }
                            </div>
                            </div>
                        </div>
                   

                        <div className="sma:px-3 md:w-1/2 lg:w-1/3 px-6 flex">
                            <div className="sma:mt-3 flex flex-col mx-auto max-w-md items-center mt-12 ">
                                <div className="py-4 border  border-gray-300 shadow-xl">
                                <form className="justify-center flex" onSubmit={handleSubmit} >
                                        <div className="bg-white rounded lg:px-8 px-5 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                                                    Enter Name*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="name" type="text" placeholder="" name="name" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="course">
                                                    Enter Course Interested*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="course" type="text" placeholder="" name="course" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                                                    Enter Email*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="email" type="text" placeholder="" name="email" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="contact">
                                                    Enter Contact Number*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="contact" type="text" placeholder="" name="contact" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="country">
                                                    Enter Country Name*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="country" type="text" placeholder="" name="country" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="location">
                                                    Enter Specific Location*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="location" type="text" placeholder="" name="location" />
                                                </div>
                                            </div>
                                           
                                            
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="message">
                                                    Enter Your Message*
                                                </label>
                                                <textarea className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 h-48"
                                                            id="message" type="text" placeholder="" name="message" >
                                                </textarea>
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mt-2">
                                                <div className="sma:w-full">
                                                <button
                  className="sma:w-full bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit">
                  Send Message
                </button>
                                          </div>      </div>
                                            </div>
                                     
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Layout>
        );
}

export default SalesforceTrainingPage;
